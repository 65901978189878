import React from 'react'
import { AgenceImages, AgencePartners } from '../../components';
import './agence.css';
const Agence = () => {
  return (
    <div className="agence">
      <AgenceImages />
      <div className="agence-text">
        <h2>Agence</h2>
        <p>L’activité de l'agence <span>grima loussouarn</span>, créée il y a une vingtaine d'année est très diversifiée. L'agence étudie tous types de projets architecturaux avec deux axes privilégiés : les projets relatifs aux salles de spectacles et de cinéma d'une part, les projets relevant du patrimoine au sens large (classé, inscrit ou non protégé) d'autre part.<br/>L’agence est située&nbsp; au 71 rue Albert Dhalenne à Saint-Ouen 93400.<br/>Les locaux partagés avec l'agence Punto accueillent 8 postes de travail en réseau, une salle de réunion, une documentation et un atelier maquette.</p>
      </div>
      <AgencePartners />
    </div>
  )
}

export default Agence