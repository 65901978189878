import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
import { Container, Row, Col } from 'react-bootstrap';
import './article.css';
import 'swiper/swiper-bundle.css';
import projetData from '../../containers/projet/projets.json';
import { useParams } from 'react-router-dom';





SwiperCore.use([Navigation, Pagination, Autoplay]);

 
const images = [
  'http://www.gl-architectes.com/images/dsc_0476.jpg',
  'http://www.gl-architectes.com/images/massy-008_fberthet2010207.jpg',
  'http://www.gl-architectes.com/images/dsc_0468.jpg',
  'http://www.gl-architectes.com/images/dsc_0475.jpg',
  'http://www.gl-architectes.com/images/20171018_085543bis.jpg'
];

const croquis = [
  'http://www.gl-architectes.com/images/ma_pg_aps_cinema-presentation-02%20-%20retouche%20fond%20blanc.jpg',
  'http://www.gl-architectes.com/images/gl_plans%20massy_pathe_01.jpg',
  'http://www.gl-architectes.com/images/gl_plans%20massy_pathe_03.jpg',
  'http://www.gl-architectes.com/images/pr%c3%a9sentation%20comm%20securite%202_page_1.jpg',
  'http://www.gl-architectes.com/images/untitled%20extract%20pages.jpg'
];

const Article = () => {
  let { projectId } = useParams();
  const projet = projetData.find((p) => String(p.url) === String(projectId)); // Filtrer les données du JSON
  


  if (!projet) {
    // Gérer le cas où le projet n'est pas trouvé
    console.log(projectId)
    console.log(projet)
    return <p>Projet non trouvé?</p>;
  }



  return (

    <Container>
      <section id="projet-details" className="projet-details">
        <Container>
          <div className="container">
        <div style={{width: 1900}}>
          <Row>
            <Swiper
              spaceBetween={30}
              slidesPerView={1}
              loop
              navigation
              pagination={{ clickable: true }}
              autoplay={{ delay: 5000, disableOnInteraction: false }}
              className="my-swiper"
            >
              {images.map((image) => (
                <SwiperSlide key={image}>
                  <img src={image} alt="images" />
                </SwiperSlide>
              ))}
            </Swiper>
          </Row>
          <Row>
            <h3>{projet.name}</h3>
            <Container>
              <Col md={8}>
                  <p>Le projet s’inscrit dans une opération d’envergure menée par Christian de Portzamparc, architecte de l’ensemble de la ZAC et du bâtiment qui abrite un centre des congrès et le complexe cinématographique.</p>
                  <p>La mission porte sur l'aménagement général intérieur de ce complexe cinéma qui compte 12 salles réparties sur 9 niveaux, comprenant la gestion des flux (séparation des circuits des entrants et des sortants) , la synthèse des contraintes techniques et de sécurité, des normes cinématographiques et une étude particulière sur la qualité des espaces publics (salons, dégagements et sanitaires).</p>
              </Col>
              <Col md={4}>
                <div className='projet-info panel widget'>
                  <dl>
                    <dt>Livraison</dt>
                    <dd>{projet.endPeriod}</dd>
                    <dt>Localisation</dt>
                    <dd>{projet.location}</dd>
                    <dt>Programme</dt>
                    <dd>{projet.category[2]}</dd>
                    <dt>Maître d’ouvrage</dt>
                    <dd>{projet.projectOwner}</dd>
                    <dt>Surface</dt>
                    <dd>{projet.area} m²</dd>
                    <dt>budget</dt>
                    <dd>{projet.budget} €</dd>
                  </dl>
                </div>
              </Col>
            </Container>
          </Row>
          <Row>
            <h1>Croquis & Plans</h1>

            <Col  md={8}>
              <Swiper
                spaceBetween={30}
                slidesPerView={1}
                loop
                navigation
                pagination={{ clickable: true }}
                autoplay={{ delay: 5000, disableOnInteraction: false }}
                className="my-swiper"
              >
                {croquis.map((image) => (
                  <SwiperSlide key={image}>
                    <img src={image} alt="images" />
                  </SwiperSlide>
                ))}
              </Swiper>
            </Col>
          </Row>
          <Row>
            <h1><span >Projets liés</span></h1>
            <Col  md={4}>
              <Swiper
                spaceBetween={30}
                slidesPerView={1}
                loop
                navigation
                pagination={{ clickable: true }}
                className='projet-related my-swiper'
              >
                {images.map((image) => (
                  <SwiperSlide key={image}>
                    <img src={image} alt="images" />
                  </SwiperSlide>
                ))}
              </Swiper>
            </Col>
          </Row>
        </div>
          </div>
        </Container>
    </section>
    </Container>
  );
};

export default Article;