import React, { useState, useEffect } from 'react';
import './projet.css';
import projetsData from './projets.json';
import images from '../../assets/img/projets/images';
import Masonry from 'react-masonry-css';
import { FiltreCategories } from './Categories';
import {Link} from 'react-router-dom';


const Filtre = () => {
    /* Définition de l'état avec useState */
    const [projets, setProjets] = useState([]);
    const [categories, setCategories] = useState([]);
    const [activeFilter, setActiveFilter] = useState('*');

    /* Utilisation de useEffect pour charger les données des projets et des catégories */
    useEffect(() => {
        setProjets(projetsData);
        /* Utilisation de setCategories avec un ensemble pour éviter les doublons */
        setCategories([
            ...new Set(
                projetsData.flatMap((projet) => projet.category)
            ),
        ]);
    }, []);

    /* Fonction pour gérer les clics sur les filtres */
    const handleFilterClick = (value) => {
        setActiveFilter(value);
    };

    const getFontSizeForCategory = (category) => {
        const taillePoliceParMot = {
            TOUS: 12,
            PRIVÉ: 24,
            CINÉMA: 48,
            RÉNOVATION: 52,
            PUBLIC: 12,
            MUSÉES: 24,
            // Ajoutez d'autres mots avec leur taille de police correspondante
        };

        return taillePoliceParMot[category] || 16; // Taille de police par défaut si le mot n'est pas dans la liste
    };

    useEffect(() => {
    }, []);

    /* Définir les paramètres de la grille de maçonnerie */
    const breakpointColumnsObj = {
        default: 4,
        1100: 3,
        700: 2,
        500: 1,
    };

    return (
        <section id="portfolio" className="portfolio">
            <div className="container-fluid">
                <div className="section-title">
                </div>
                <div className="row">
                    <div className="col-lg-12 d-flex justify-content-center">
                        <ul id="portfolio-flters">
                            <li
                                onClick={() => handleFilterClick('*')}
                                className={activeFilter === '*' ? 'filter-active' : ''}
                                style={{ fontSize: `24px` }}
                            >
                                Tous
                            </li>
                            {categories.map((cat) => (
                                <li
                                    key={cat}
                                    onClick={() => handleFilterClick(cat)}
                                    className={
                                        activeFilter.includes(cat) ? 'filter-active' : ''
                                    }
                                    style={{ fontSize: `${FiltreCategories.find((item) => item.category === cat)?.size}px` }}
                                >
                                    {cat}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>

                {/* Utiliser le composant Masonry pour organiser les projets */}
                <Masonry
                    breakpointCols={breakpointColumnsObj}
                    className="my-masonry-grid"
                    columnClassName="my-masonry-grid_column aside-portfolio"
                >
                    {projets
                        .filter((projet) => {
                            if (Array.isArray(projet.category)) {
                                return (
                                    activeFilter === '*' ||
                                    projet.category.some((cat) =>
                                        activeFilter.includes(cat)
                                    )
                                );
                            } else {
                                return activeFilter === '*' || projet.category === activeFilter;
                            }
                        })
                        .map((projet) => (
                            <div
                                key={projet.id}
                                className={`portfolio-item filter-${
                                    Array.isArray(projet.category)
                                        ? projet.category.join(' ').toLowerCase()
                                        : projet.category.toLowerCase()
                                }`}
                                style={{ fontSize: `${getFontSizeForCategory(projet.category)}px` }}

                            >
                                <div className="portfolio-wrap">
                                    <img
                                        src={images[projet.image.publicUrl]}
                                        className="img-fluid"
                                        alt=""
                                    />
                                    <Link to={projet.linkDemo} data-gallery="portfolioGallery">
                                    <div className="portfolio-info">
                                        
                                            <h4>{projet.name.toUpperCase()}</h4>
                                            <h4>{projet.location.toUpperCase()}</h4>
                                            <p className='portfolio-category'>
                                                {Array.isArray(projet.category)
                                                    ? projet.category.join(', ')
                                                    : projet.category}
                                            </p>
                                                
                                    </div>
                                    </Link>
                                </div>
                            </div>
                        ))}
                </Masonry>
            </div>
        </section>
    );
};

export default Filtre;