import React from 'react';
import projetData from '../../containers/projet/projets.json';
import Carousel from 'react-bootstrap/Carousel';

const MainRelatedProjects = ({ categories }) => {
    // Vérifiez si les catégories sont définies avant de continuer
    if (!categories || categories.length === 0) {
        console.log('Catégories non définies');
        return <div>Catégories non définies</div>;
    }
    console.log('Categories: ', categories);
    // Trouvez les projets liés ayant les mêmes catégories que les catégories données
    const relatedProjects = projetData.filter((proj) =>
        proj.category.some((cat) => categories.includes(cat))
    );

    console.log('Projets liés: ', relatedProjects);
    return (
        <div>
            <Carousel>
                {relatedProjects.map((proj) => (
                    <Carousel.Item key={proj.id}>
                        <a href={proj.linkDemo}>
                            <img src={proj.image.publicUrl} alt={proj.image.alt} />
                            <Carousel.Caption>
                                <h3>{proj.name}</h3>
                                <p>{proj.location}</p>
                            </Carousel.Caption>
                        </a>
                    </Carousel.Item>
                ))}
            </Carousel>
        </div>
    );
};

export default MainRelatedProjects;
