import React from 'react'
import Carte from '../../assets/ContactPlan.webp';
import './contact.css';


const Contact = () => {
  return (
    <div className="contact">
        <h2 className="contact-title">Contact</h2>
        <div class="contact-info">
          <p>71 rue Albert Dhalenne 93400 Saint-Ouen / 0147871895 / gl-architectes@gl-architectes.com</p>
        </div>
        <div className='contact-carte'>
        <img className='carte'
                src={Carte}
                alt="Carte"
            />
        </div>
        
    </div>
  )

}

export default Contact