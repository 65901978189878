import React, { useState, useEffect } from 'react';
import images from '../../assets/img/projets/images';  
import projetsData from '../../containers/projet/projets.json';


import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './articleSlider.css';

const ArticleSlider = () => {

  const [indexes, setIndexes] = useState([]);
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    const randomIndexes = new Set();

    while (randomIndexes.size < 5) {
      randomIndexes.add(
        Math.floor(Math.random() * projetsData.length)  
      );
    }

    setIndexes([...randomIndexes]);

  }, []);

  return (
    <div className="slider">
      
      {indexes.map((i, index) => (
        <div 
          key={i}
          className={index === current ? "slide active" : "slide"}
        >
          <img 
            src={images[projetsData[i].image.publicUrl]}
            alt={projetsData[i].titre} 
          />

          <div 
            className="name"
            onClick={() => window.open(projetsData[i].linkDemo)}
          >
          </div>
        </div>
      ))}

      <div className="nav">
        {indexes.map((i, index) => (
          <button
            key={index}
            onClick={() => setCurrent(index)} 
            className={index === current ? "dot active" : "dot"}
          >
          </button>
        ))}
      </div>

    </div>
  );
}

export default ArticleSlider;