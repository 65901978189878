import React from 'react'
import projetData from '../../containers/projet/projets.json';
import { useParams } from 'react-router-dom';
import MainDescription from './MainDescription';
import MainSwiper from './MainSwiper';
import MainRelatedProjects from './MainRelatedProjects';
import './MainArticle.css';

const MainArticle = () => {
  let { projectId } = useParams();
  const projet = projetData.find((p) => String(p.url) === String(projectId)); // Filtrer les données du JSON
  return (
    <div className='main-article'>
        <MainSwiper pictures={projet.SwiperLandscape} />
        <br/>
        <h2>{projet.name.toUpperCase()}, {projet.location.toUpperCase()}</h2>
        <br/>
        <br/>
        <div className="panel-info">
              <div className='description-info' >{projet.description.map(des => <div>{des}</div>)}</div>
              <div className='technical-info'><MainDescription projet={projet} /></div>
        </div>
        <MainRelatedProjects categories={projet.category} />
    </div>
  )
}

export default MainArticle