import React from 'react'
import { ArticleSlider } from '../../components';
import './home.css';




const Home = () => {

  return (
    <>
    <div className='home'>
      <ArticleSlider />
    </div>
    <br></br>
    </>
  )
}

export default Home