import React, { useState, useEffect } from 'react';
import './actualites.css';
import images from '../../assets/img/projets/images';
import jsonData from './articles.json';


const Actualites = () => {
  const articles = jsonData.articles;
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [modalImage, setModalImage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const articlesPerPage = 9;

  useEffect(() => {
      // Fetch the modal image when selectedArticle changes
      const fetchModalImage = async () => {
          try {
              const article = articles.find((article) => article.id === selectedArticle);
              setModalImage(article.image);
          } catch (error) {
              console.log(error);
          }
      };

      if (selectedArticle) {
          fetchModalImage();
      }
  }, [selectedArticle]);

  const openModal = (id) => {
      setSelectedArticle(id);
  };

  const closeModal = () => {
      setSelectedArticle(null);
      setModalImage(null);
  };

  // Calculate the index range for the current page
  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentArticles = articles.slice(indexOfFirstArticle, indexOfLastArticle);

  const paginate = (pageNumber) => {
      setCurrentPage(pageNumber);
  };

  return (
      <div className='gls_actualites'>
          <ul>
              {currentArticles.map((article) => (
                  <li key={article.id}>
                      <img src={images[article.image]} alt={article.description} onClick={() => openModal(article.id)} />
                      <p>{article.description}</p>
                      <p>{article.date}</p>
                  </li>
              ))}
          </ul>

          {selectedArticle && (
              <div className='modal' onClick={closeModal}>
                  <span className='close'>&times;</span>
                  {modalImage && <img className='modal-content' src={modalImage} alt='' />}
              </div>
          )}

          <div className='pagination'>
              {Array.from({ length: Math.ceil(articles.length / articlesPerPage) }, (_, index) => (
                  <button key={index} onClick={() => paginate(index + 1)}>
                      {index + 1}
                  </button>
              ))}
          </div>
      </div>
  );
};

export default Actualites;