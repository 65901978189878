export const FiltreCategories = [
    { category: "TOUS", size: 14 },
    { category: "PATRIMOINE", size: 13 },
    { category: "AMÉNAGEMENTS", size: 8 },
    { category: "FORMATIONS", size: 8 },
    { category: "RÉNOVATION", size: 13 },
    { category: "ATELIERS", size: 8 },
    { category: "PRIVÉ", size: 15 },
    { category: "SALLES DE SPECTACLES", size: 13 },
    { category: "CINÉMAS", size: 13 },
    { category: "ADMINISTRATIF", size: 10 },
    { category: "NEUF", size: 15 },
    { category: "MUSÉES", size: 13 },
    { category: "PROGRAMMATION", size: 10 },
    { category: "RESTAURATION", size: 15 },
    { category: "BUREAUX", size: 8 },
    { category: "EXTENSION", size: 15 },
    { category: "RÉGIONS", size: 13 },
    { category: "CHÂTEAUX", size: 13 },
    { category: "THÉÂTRES", size: 10 },
    { category: "PUBLIC", size: 15 }
];
