const images = {};
const requireImages = require.context(
  './', // Chemin relatif du dossier
  true, // Inclure les sous-dossiers ?
  /\.(png|jpe?g|gif|webp)$/ // Pattern pour récupérer les images
);

requireImages.keys().forEach((fileName) => {
  images[fileName] = requireImages(fileName);
});

export default images;


