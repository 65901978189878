import React, { useState, useEffect } from 'react';
import images from '../../assets/img/projets/images';  

import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './articleSlider.css';

const MainSwiper = ({ pictures }) => {
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrent((prevCurrent) => (prevCurrent + 1) % pictures.length);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="slider">
      
      {pictures.map((pic, index) => (
        <div 
          key={index}
          className={index === current ? "slide active" : "slide"}
        >
          <img 
            src={images[pic]}
          />
        </div>
      ))}

      <div className="nav">
        {pictures.map((pic, index) => (
          <button
            key={index}
            onClick={() => setCurrent(index)} 
            className={index === current ? "dot active" : "dot"}
          >
          </button>
        ))}
      </div>

    </div>
  );
}

export default MainSwiper;
